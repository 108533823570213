.feature{
  display: flex;
  margin-bottom: 120px;
  @media(max-width: 767px){
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 80px;
  }
  &--to-bottom{
    align-items: flex-end;
  }
  &__icon{
    width: 111px;
    height: 111px;
    border: 15px solid #f3f3f8;
    @media(max-width: 767px){
      width: 90px;
      min-width: 90px;
      height: 90px;
    }
    .svg-icon{
      margin-top: -15px;
      @media(max-width: 1023px){
        *{
          stroke: #ff7e58;
        }
      }
      fill: #414141;
      min-width: 100px;
      min-height: 100px;
      max-width: 130px;
      max-height: 130px;
      transition: 0.2s ease-in;
      *{
        transition: 0.2s ease-in;
      }
      &:hover{
        *{
          stroke: #ff7e58;
        }

      }
    }
  }
  &__content{
    margin-left: 45px;
    font-size: 18px;
    @media(max-width: 767px){
      line-height: 24px;
      margin-left: 0px;
      margin-top: 30px;
      text-align: center;
    }
    color: #000000;
    line-height: 30px;
    p{
      margin-bottom: 0;
    }
  }
  &__title{
    font-size: 20px;
    color: #ff7e58;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 25px;
  }
}
