.projects{
  width: 100%;
  @include bgTitle('3 примера');
  min-height: 1050px;
  padding-bottom: 60px;
  &:after{
    height: 446px;
    content: '';
    width: 100%;
    @extend %bg-cover;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-image: url(../images/bgs/projects-bg.png);
  }
  &__plate{
    @extend %plate;
  }
  &__title{
    @extend %title;
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @extend %title--underscore;
  }
  &__project{
    min-height: 760px;
    @extend %bg-cover;
    background-image: url(../images/bgs/project-bg.png);
    @media(max-width: 767px){
      padding: 21px;
    }
    padding: 41px;
  }
}
