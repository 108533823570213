.modal{
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  z-index: 50;
  display: flex;
  align-items: center;
  @media(max-width: 767px){
    overflow-y: scroll;
  }
  //statements
  opacity: 0;
  pointer-events: none;
  transition: 0.2s ease-in;
  $parent: &;
  &--response{
    #{$parent}__wrapper{
      &:before{
        border: 15px solid #323947;
      }
    }
    #{$parent}__content{
      background-color: #ff7e58;
    }
  }

  &--enabled{
    opacity: 1;
    pointer-events: auto;
    #{$parent}__wrapper{
      transform: scale(1) translateY(0);
    }
  }
  &__layout{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 0;
    background-color: rgba(255,255,255,0.4);
  }
  &__wrapper{
    transform: scale(0.7) translateY(-200px);
    transition: 0.2s ease-in;
    position: relative;
    width: 100%;
    max-width: 730px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1;

    &:before{
      position: absolute;
      width: 138px;
      height: 138px;
      border: 15px solid #ff7e58;
      content: '';
      top: -31px;
      left: -31px;
      z-index: -1;
    }
  }
  &__content{
    color: #fff;
    width: 100%;
    padding: 50px;
    background-color: #323947;
  }
  &__close{
    @media(max-width: 767px){
      top: 30px;
      right: 30px;
      z-index: 2;
    }
    position: absolute;
    right: -42px;
    top: 0;
    width: 32px;
    height: 32px;
    @extend %bg-contain;
    background-image: url(../images/icons/icon-orange.png);
  }
}
