.steps{
  width: 100%;
  @include bgTitleRight('6 шагов');
  padding-bottom: 60px;
  &__plate{
    margin-top: 100px;
    @extend %plate;
    @extend %plate--noPadd;
  }
  &__title{
    max-width: 670px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    @extend %title;
    @extend %title--underscore;
  }
  &__box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin: 0;
    position: relative;
    &:after{
      @media(max-width: 1365px){
        display: none;
      }
      content: '';
      position: absolute;
      width: 922px;
      height: 517px;
      right: -80px;
      top: 75px;
      background-image: url(../images/steps/step_arrows.png);
      z-index: 2;
    }
  }


//one step styles

  &__item{
    width: calc(100% / 3);
    min-width: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    &--lg-o-4{
      @media(min-width: 1366px){
        order: 4;
      }
    }
    &--lg-o-5{
      @media(min-width: 1366px){
        order: 5;
      }
    }
    &--lg-o-6{
      @media(min-width: 1366px){
        order: 6;
      }
    }
  }
  &__square{
    width: 190px;
    height: 190px;
    border: 15px solid #ff7e58;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 110px;
    .svg-icon{
      @media(max-width: 1023px){
        *{
          stroke: #ff7e58;
        }
      }
      min-width: 110px;
      min-height: 110px;
      max-width: 130px;
      max-height: 130px;
      *{
        transition: 0.2s ease-in;
      }
      &:hover{
        *{
          stroke: #ff7e58;
        }
      }
    }
  }
  &__step-num{
    position: absolute;
    top: -60px;
    right: -15px;
    text-transform: uppercase;
    font-size: 30px;
    color: #ff7e58;
    font-weight: 600;
  }
  &__desc{
    margin-top: 24px;
    color: #000000;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
  }


}
