.reviews{
  width: 100%;
  padding-bottom: 60px;
  &--bg-title{
    @include bgTitleRight('Отзывы')
  }
  &__plate{
    @extend %plate;
  }
  &__title{
    @extend %title;
    @extend %title--underscore;
    max-width: 650px;
    color: #323947;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  &__box{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__col-2-p-30{
    width: calc((100% / 2) - 30px);
    @media(max-width: 767px){
      width: 100%;
      margin-bottom: 40px;
    }
  }
  &__video{
    @extend %bg-cover;
    background-image: url(../images/bgs/video-bg.png);
    min-height: 340px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: -13px 13px 89px -13px rgba(0,0,0,0.75);
  }
  &__list{
    padding-left: 0;
    list-style: none;
    margin: 20px 0;
  }
  &__item{

  }
}
