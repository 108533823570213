%btn{
  display: inline-flex;
  align-items: center;
  height: 80px;
  @media(max-width: 767px){
    height: 60px;
    padding-left: 40px;
    font-size: 14px;
  }
  padding-left: 60px;
  padding-right: 65px;
  border-radius: 40px;
  background-color: #ff7249;
  color: #fff;
  position: relative;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  transition: 0.15s ease-in;
  cursor: default;
  border: 0;
  &:after{
    transition: 0.15s ease-in;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 24px;
    margin: auto;
    width: 21px;
    height: 15px;
    background-image: url(../images/icons/arrow-white.png);
    content: '';
  }
  &:hover{
    background-color: #323947;
    &:after{
      background-image: url(../images/icons/arrow-orange.png);
    }
  }
  &--disabled{
    cursor: default;
    opacity: 0.7;
    &:hover{
      background-color: #ff7249;
      &:after{
        background-image: url(../images/icons/arrow-white.png);
      }
    }
    
  }
  &:focus{
    outline: none;
  }
}

%tab-btn{
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #2c2c2c;
  font-weight: 300;
  cursor: default;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 600;
  background: #e1dbd7;
  &--light{
    background-color: #f3f3f8;
  }
  &--active{
    background: #ff7249;
    color: #ffffff;
  }
  border-radius: 31px;
  min-height: 63px;
}
