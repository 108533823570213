.features{
  width: 100%;
  &--pt-90{
    padding-top: 90px;
  }
  &--bg-title{
    @include bgTitle('поможем')
  }
  &__plate{
    @extend %plate;
  }
  &__box{
    @extend %box;
    @extend %box--center-y-xs;
  }
  &__title{
    text-align: center;
    @extend %title;
    @extend %title--underscore;
    max-width: 662px;
    margin-left: auto;
    margin-right: auto;
  }
  &__item{
    @media(max-width: 767px){
      width: 100%;
      max-width: 360px;
      margin-left: 0;
    }
    width: calc(50% - 100px);
    @media(min-width: 768px){
      &:nth-of-type(2n){
        margin-left: 100px;
      }
    }
    &--min-space{
      @media(max-width: 767px){
        width: 100%;
        max-width: 320px;
      }
      width: calc(50% - 50px);
      @media(min-width: 768px){
        &:nth-of-type(2n){
          margin-left: 100px;
        }
      }
    }
  }
  &__video{
    box-shadow: 0px 15px 99px -17px rgba(0,0,0,0.75);
    margin-bottom: 110px;
    iframe{
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 100% !important;
    }
  }
}
