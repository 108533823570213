.checkbox{
	width: 100%;
	@extend %row;
	@extend %row--center-y;
	$parent: &;
	&__label{
		margin-left: 12px;
		font-size: 14px;
		display: inline-flex;
		@extend %row--center-y;
	}
	&__checker{
		width: 22px;
		min-width: 22px;
		height: 22px;
		border-radius: 4px;
		border: 1px solid #e83f30;
		background: #fff;
		position: relative;
		&--enabled{
			border-color: #6d1f81;
			#{$parent}__check{
				transform: scale(0.7);
				opacity: 1;
				i{
					transform: scale(0.7);
				}
			}
		}
	}
	&__check{
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		min-width: 16px;
		min-height: 13px;
		color: #6d1f81;
		background-image: url(../images/icons/check.svg);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		//statement disabled
		opacity: 0;
		transform: scale(0.3);
		transition: 0.18s cubic-bezier(.26,.96,.86,1.34);
	}
}
