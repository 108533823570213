.footer{
  width: 100%;
  min-height: 200px;
  display: flex;
  align-items: center;
  background-color: #ece9e6;
  &__plate{
    @extend %plate;
  }
}
