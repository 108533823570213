
@import './core/flex';
@import './core/variables';
@import './core/mixins';
@import './core/fonts';
@import "plugins/swiper.scss";
@import "materials/box.scss";
@import "materials/btn.scss";
@import "materials/close-icon.scss";
@import "materials/content.scss";
@import "materials/input.scss";
@import "materials/plate.scss";
@import "materials/title.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/auditory/auditory.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/fb1/fb1.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/fb2/fb2.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/fb3/fb3.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/features/features.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/footer/footer.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/gallery/gallery.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/header/header.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/partners/partners.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/projects/projects.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/reviews/reviews.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/serts/serts.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/services/services.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/steps/steps.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/blocks/usp/usp.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/box/box.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/checkbox/checkbox.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/feature/feature.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/gallery/gallery.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/jform/jform.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/modal/modal.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/project/project.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/review/review.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/service/service.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/slider/slider.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/tab/tab.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/team/team.scss";
@import "/home/casper/projects/umka-lp/static/src/_modules/components/video/video.scss";
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
body{
  overflow-x: hidden;
  font-family: "proximaNova", sans-serif;
  padding: 0;
  margin: 0;
  color: #333;
  // min-width: 1240px;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
    -moz-appearance:textfield;
}
