.video{
  background-image: url(../images/bgs/video-bg.png);
  height: 508px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  &__btn{
    width: 59px;
    height: 80px;
    background-image: url(../images/icons/play-orange.svg);
    transition: 0.25s ease-out;
    &:hover{
      transform: scale(1.2);
    }
  }
}
