.gal{
  position: relative;
  z-index: 2;
  width: 100%;
  min-height: 750px;
  @media(max-width: 767px){
    min-height: 400px;
  }
  padding-bottom: 70px;
  &:after{
    height: 446px;
    content: '';
    width: 100%;
    @extend %bg-cover;
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
    background-image: url(../images/bgs/projects-bg.png);
  }
  &--bg-title{
    @include bgTitleRight('оборудуем')
  }
  &__plate{
    @extend %plate;
  }
  &__title{
    margin-top: 40px;
    max-width: 900px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    @extend %title;
    @extend %title--underscore;
  }
  &__slide{
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      display: block;
      width: 100%;
      height: auto !important;
      max-height: 100%;
    }
  }
  &__slider{
    .swiper-wrapper{
      align-items: center;
    }
    margin-top: 50px;
    position: relative;
    img{
      max-width: 100%;
    }
  }
  &__top{
    box-shadow: 18px 14px 80px -9px rgba(0,0,0,0.55);
    background-color: rgba(0,0,0,0.32);
    .swiper-slide{
      width: 100%;
    }
    img{
      min-height: 100%;
    }
  }
  &__thumbs{
    margin-top: 46px;
    .swiper-slide{
      width: calc((100% / 3) - 32px);
      position: relative;
      &:before, &:after{
        transition: 0.2s ease-in;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        content: '';
        opacity: 0;
      }
      &:before{
        z-index: 1;
        background-color: rgba(81, 86, 101, 0.45);
      }
      &:after{
        z-index: 2;
        width: 30px;
        height: 30px;
        @extend %bg-contain;
        background-image: url(../images/icons/loop-orange.svg);
      }
      &:hover{
        &:before,&:after{
          opacity: 1;
        }
      }
    }
  }

}
