%plate{
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	max-width: 1140px;
	position: relative;
	padding-left: 24px;
	padding-right: 24px;
	@media(max-width: 1139px){
		padding-left: 15px;
		padding-right: 15px;
	}
	&--xs-noPadd{
		@media(max-width: 767px){
			padding-left: 0;
			padding-right: 0;
		}
	}
	&--noPadd{
		padding-left: 0;
		padding-right: 0;
	}
}
