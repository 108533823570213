.partners{
  width: 100%;
  min-height: 890px;
  @extend %bg-cover;
  background-image: url(../images/bgs/partners-bg.png);
  &__plate{
    @extend %plate;
    padding-top: 90px;
  }
  &__title{
    max-width: 540px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    @extend %title;
    @extend %title--underscore;
  }
  &__desc{
    @media(max-width: 767px){
      line-height: 28px;
      font-size: 18px;
    }
    margin-top: 20px;
    max-width: 1040px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 20px;
    color: #323947;
    line-height: 36px;
    margin-bottom: 20px;
  }
  &__box{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  &__item{
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 30px;
    width: 23%;
    min-width: 150px;
    margin-left: 1%;
    margin-right: 1%;
    img{
      width: 100%;
    }
  }
}
