.tab{
	width: 100%;
	&__btn-list{
		width: 100%;
		margin: 0;
		padding: 5px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		list-style-type: none;
		min-height: 80px;
		border-radius: 40px;
		border: 4px solid #ff7e58;
		@media(max-width: 767px){
			flex-wrap: wrap;
			justify-content: center;
		}
	}
	&__btn{
		width: calc((100% / 3) - 8px);
		min-width: 230px;
		text-align: center;
		@media(max-width: 767px){
			margin-right: 7px;
			margin-left: 7px;
			&:not(:last-of-type){
				margin-bottom: 15px;
			}

		}
		@extend %tab-btn;
		&--active{
			@extend %tab-btn--active;
		}
		&--light{
			@extend %tab-btn--light;
		}
	}
	&__main-list{
		min-height: calc(100% - 75px);
		padding: 0;
		margin: 0;
		display: flex;
		justify-content: center;
	}
	&__content{
		width: 100%;
		display: none;
		padding-top: 50px;
		&--active{
			display: block;
		}
	}
}
