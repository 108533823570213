.box{
	width: 100%;
	position: relative;
	&--title-bg{
		@include bgTitleRight('Умка-про')
	}
	&--h-900{
		padding-bottom: 80px;
		@media(min-width: 768px){
			min-height: 900px;
		}

	}

	&--h-800{
		padding-bottom: 80px;
		@media(min-width: 768px){
			min-height: 800px;
		}
	}
	&__plate{
		@extend %plate;
		position: relative;
		z-index: 2;
	}
	&__span{
		&--orange{
			color: #ff7e58;
		}
	}
	&__title{
		max-width: 670px;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		@extend %title;
		@extend %title--underscore;
		&--pt-40{
			padding-top: 40px;
		}
		&--sub{
			font-size: 36px;
			//no underscore
			&:after{
				display: none;
			}
		}
	}
	&__back-layout{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		z-index: 1;
		@extend %bg-cover;
		background-color: #fcfdfd;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		&--img-header{
			background-image: url(../images/bgs/header-bg.png);
		}
		&--img-top{
			align-items: stretch;
		}
	}
	&__back-img{
		@media(max-width: 767px){
			display: none;
		}
		max-width: 50%;
		position: relative;
		overflow: hidden;
		&--square-bottom{
			&:before{
				content: '';
				position: absolute;
				border: 15px solid #ff7e58;
				height: 210px;
				width: 210px;
				bottom: 40px;
				left: 550px;
			}
		}
	}
	&__content{
		max-width: 640px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		@media(max-width: 767px){
			overflow: hidden;
		}
		&--lh-more{
			line-height: 1.4;
			>*{
				margin: 4px 0;
			}
		}
		&--mt-20{
			margin-top: 20px;
		}
		&--mt-190{
			margin-top: 190px;
		}
		&--mt-100-xs{
			@media(max-width: 767px){
				margin-top: 100px;
			}
		}
		&--square{
			position: relative;
			&:before{
				position: absolute;
				content: '';
				border: 15px solid #f3f3f8;
				width: 321px;
				height: 321px;
				@media(max-width: 767px){
					width: 240px;
					height: 240px;
					top: 20px;
					left: 30px;
				}
				top: -18px;
				left: -30px;
			}
		}
	}
	&__btn-group{
		width: 100%;
		max-width: 350px;
		display: flex;
		flex-direction: column;
	}
	&__row{
		display: flex;
		flex-wrap: wrap;
	}
	&__img{
		&--shadow{
			box-shadow: 3px 7px 134px -32px rgba(0,0,0,0.75);
		}
	}
	&__col{
		img{
			max-width: 100%;
		}
		&--mw-450{
			width: 100%;
			max-width: 450px;
		}
		&--mw-640{
			width: 100%;
			max-width: 640px;
		}
		&--content{
			@extend %content-white-bg;
		}
		&--pl-20{
			padding-left: 20px;
			@media(max-width: 767px){
				padding-left: 0;
				padding-top: 20px;
			}
		}
	}
	&__col-img{
		width: calc(100% / 2);
	}
	&__txt{
		color: #323947;
		position: relative;
		z-index: 2;
		&--fff{
			color: #fff;
		}
		&--pt-10{
			padding-top: 10px;
		}
		&--pt-30{
			padding-top: 30px;
		}
		&--upper{
			text-transform: uppercase;
		}
		&--fw-600{
			font-weight: 600;
		}
		&--fw-700{
			font-weight: 700;
		}


		&--fs-18{
			font-size: 18px;
			@media(max-width: 767px){
				font-size: 14px;
			}
		}
		&--fs-24{
			font-size: 24px;
			@media(max-width: 767px){
				font-size: 16px;
			}
		}
		&--fs-30{
			font-size: 30px;
			@media(max-width: 767px){
				font-size: 24px;
			}
		}
		&--fs-48{
			font-size: 48px;
			@media(max-width: 767px){
				font-size: 32px;
			}
		}
		&--fs-60{
			font-size: 60px;
			@media(max-width: 767px){
				font-size: 48px;
			}
		}
		&--fs-70{
			font-size: 70px;
			@media(max-width: 767px){
				font-size: 50px;
			}
		}


		&--mw-440{
			max-width: 440px;
		}
		&--mw-520{
			max-width: 520px;
		}
		&--desc{
			line-height: 52px;
			@media(max-width: 767px){
				line-height: 36px;
			}
		}
		&--right-line{
			position: relative;
			&:after{
				content: '';
				position: absolute;
				right: -260px;
				top: 10px;
				bottom: 0;
				margin: auto;
				width: 210px;
				height: 4px;
				background-color: #ff7e58;
			}
		}
	}
	&__last{
		@extend %last;
		@extend %last--subtitle;
		@extend %last--medal-bg;
		@extend %last--t-center;
	}
	&__tab{
		margin-top: 100px;
	}
	&__btn{
		margin-top: 30px;
		@extend %btn;
	}
}
