.slider{

  &__bullet{
    position: relative;
    width: 21px;
    height: 21px;
    border-radius: 21px;
    border: 3px solid #ff7e58;
    margin-left: 4px;
    margin-right: 4px;
    &:before{
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 9px;
      height: 9px;
      border-radius: 9px;
      background-color: #e1dbd7;
    }
    &--active{
      width: 27px;
      height: 27px;
      &:before{
        background-color: #ff7e58;
      }
    }
  }
  &__chevs{
    position: absolute;
    top: 0;
    bottom: 100px;
    margin: auto;
    height: 100px;
    width: 100%;
    &--lg{
      @media(max-width: 1380px){
        display: none;
      }
    }
    &--to-top{
      bottom: 0;
      top: 100px;
      z-index: 3;
    }
  }
  &__chev{
    width: 100px;
    height: 100px;
    border: 15px solid #ff7e58;
    position: absolute;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 28px;
      height: 46px;
      background-image: url(../images/icons/chev-left-dark.svg);
    }
    &--left{
      left: -130px;
    }
    &--right{
      right: -130px;
      &:before{
        transform: rotate(-180deg);
      }
    }
  }
  &__pagination{
    margin-top: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
