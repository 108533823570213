.serts{
  width: 100%;
  min-height: 890px;
  @extend %bg-cover;
  background-image: url(../images/bgs/serts-bg.png);
  &__plate{
    @extend %plate;
  }
  &__slide-wrapper{
    align-items: stretch;
  }
  &__title{
    padding-top: 90px;
    @extend %title;
    @extend %title--underscore;
    max-width: 920px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  &__slide{
    width: 362px !important;
    height: 482px !important;
    position: relative;
    display: flex;
    padding: 30px 42px 30px 27px;
    margin-right: 3px;
    @media(max-width: 767px){
      margin-left: auto;
      margin-right: auto;
    }
    &:after{
      z-index: 2;
      pointer-events: none;
      position: absolute;
      content: '';
      width: 100%;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      @extend %bg-contain;
      background-image: url(../images/serts/border.png);
    }
  }
  &__thumb{
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    img{
      max-width: 100%;
      max-height: 100%;
    }
    position: relative;
    z-index: 2;
    &:before{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(81, 85, 99, 0.85);
      content: '';
      opacity: 0;
      transition: 0.2s ease-in;
    }
    &:after{
      position: absolute;
      width: 30px;
      height: 30px;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      content: '';
      opacity: 0;
      transition: 0.2s ease-in;
      @extend %bg-contain;

      background-image: url(../images/icons/loop-orange.svg);
    }
    &:hover{
      &:after, &:before{
        opacity: 1;
      }
    }
  }
}
