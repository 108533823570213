.jform{
  margin-top: 10px;
  &--mw-350{
    max-width: 350px;
  }
  &--centered{
    margin-left: auto;
    margin-right: auto;
  }
  &__field{
    margin-top: 11px;
    &--name{
      @extend %field;
      @extend %field--name;
    }
    &--phone{
      @extend %field;
      @extend %field--phone;
    }
  }
  &__input{
    @extend %input;
  }
  &__submit{
    margin-top: 11px;
    @extend %btn;
    &[disabled]{
      @extend %btn--disabled;
    }
    &--full-w{
      width: 100%;
    }
    &--no-hover{
      &:hover{
        cursor: pointer;
        background-color: #FF7249
      }
    }
  }
  &__checkbox{
    margin: 12px 0;
  }
}
