.header{
  width: 100%;
  position: relative;
  z-index: 2;
  &__plate{
    @extend %plate;
  }
  &__row{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    @media(max-width: 767px){
      flex-direction: column;
      align-items: flex-end;
    }
  }
  &__logo{
    width: 256px;
    height: 43px;
    background-image: url(../images/logo.png);
    @extend %bg-contain;
  }
  &__description{
    width: 100%;
    max-width: 355px;
    font-size: 22px;
    font-weight: 600;
    @media(max-width: 767px){
      padding-left: 50px;
      text-align: right;
      max-width: 400px;
    }
  }
  &__contacts{
    display: flex;
    flex-direction: column;
  }
  &__number{
    color: #323947;
    font-size: 22px;
    font-weight: 600;
    position: relative;
    padding-left: 24px;
    text-decoration: none;
    display: block;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 15px;
      height: 7px;
      @extend %bg-contain;
      background-image: url(../images/icons/phone-orange.svg);
    }
    &--top-10{
      margin-top: 10px;
    }
    &--no-icon{
      &:before{
        background-image: none;
      }
    }
  }
  &__wrapper{
    &:not(:last-of-type){
      margin-bottom: 10px;
    }
    &--a_right{
      text-align: right;
    }
  }
  &__dotted-btn{
    display: inline;
    color: #ff7e58;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 2px dotted #ff7e58;
    cursor: pointer;
  }
}
