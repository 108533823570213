%content-dark-bg{
  h3,h4,h5,h6{
    color: #ff7e58;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 16px;
    margin: 0;
  }
  span, a{
    color: #fff;
  }
  p{
    color: #fff;
    @media(max-width: 767px){
      font-size: 18px;
    }
    font-size: 20px;
    margin: 0;
    &:not(:last-of-type){
      padding-bottom: 30px;
    }

  }
}
%content-white-bg{
  p, span, a{
    font-size: 18px;
    color: #323947;
    line-height: 30px;
    @media(max-width: 767px){
      line-height: 26px;
      font-size: 16px;
    }
  }
  p{
    margin: 0;
  }
}
