@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaBlack.otf') ;
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaBlackItalic.otf') ;
  font-weight: 800;
  font-style: italic;
}


@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaExtrabold.otf') ;
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaExtraboldItalic.otf') ;
  font-weight: 700;
  font-style: italic;
}


@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaBold.otf') ;
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaBoldItalic.otf') ;
  font-weight: 600;
  font-style: italic;
}


@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaSemibold.otf') ;
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaSemiboldItalic.otf') ;
  font-weight: 500;
  font-style: italic;
}


@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaRegular.otf'), ;
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaRegularItalic.otf');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaThin.otf') ;
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'proximaNova';
  src: url('../images/fonts/proximaNova/ProximaNovaThinItalic.otf') ;
  font-weight: 300;
  font-style: italic;
}
