%input{
  width: 100%;
  height: 80px;
  border-radius: 40px;
  border: 0;
  background-color: #f3f3f8;
  display: flex;
  align-items: center;
  padding-left: 60px;
  outline: none;
  font-size: 20px;
  font-family: 'proximaNova';
  &::placeholder{
    color: #323947;
    font-weight: 500;
  }
  &:focus{
    &::placeholder{
      opacity: 0;
    }
  }
}
%field{
  position: relative;
  &:before{
    position: absolute;
    content: '';

    top: 0;
    bottom: 0;
    left: 30px;
    margin: auto;
  }
  &--name{
    &:before{
      width: 15px;
      height: 20px;
      background-image: url(../images/icons/orange-man-sm.svg);
    }
  }
  &--phone{
    &:before{
      width: 19px;
      height: 19px;
      background-image: url(../images/icons/orange-phone-sm.svg);
    }
  }
}
