.services{
  width: 100%;
  padding-bottom: 56px;
  &--bg-title{
    @include bgTitle('берем на себя')
  }
  &__plate{
    @extend %plate;
  }
  &__title{
    text-align: center;
    @extend %title;
    @extend %title--underscore;
  }
  &__description{
    text-align: center;
    line-height: 36px;
    font-size: 20px;
    @media(max-width: 767px){
      font-size: 18px;
      line-height: 24px;
    }
    color: #323947;
    p{
      margin-top: 0;
    }
    .bold{
      font-weight: 600;
    }
  }
  &__box{
    counter-reset: my-badass-counter;
    @extend %box;
    @media(max-width: 1200px){
      justify-content: center;
    }
    @media(min-width: 1201px){
      justify-content: space-between;
    }

  }
  &__item{
    @media(max-width: 1200px){
      max-width: 600px;
      width: 100%;
      padding-bottom: 50px;
    }
    @media(min-width: 1201px){
      width: calc(50% - 80px);
      &:nth-of-type(2n){
        margin-left: 80px;
        margin-top: 97px;
      }
    }
  }
  &__last{
    @extend %last;
    @extend %last--title;
    @extend %last--ok-bg;
    max-width: 450px;
  }
}
