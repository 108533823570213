%title{
  font-size: 48px;
  @media(max-width: 767px){
    font-size: 34px;
  }
  text-transform: uppercase;
  font-weight: 400;
  margin-top: 30px;
  padding-bottom: 35px;
  position: relative;
  &--underscore{
    &:after{
      content: '';
      position: absolute;
      margin: auto;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      width: 109px;
      background-color: #ff7e58;
    }
  }

  span{
    font-weight: 600;
  }
}
@mixin bgTitle($content){
  position: relative;
  &:before{
    top: -60px;
    width: 100%;
    overflow: hidden;
    margin-left: 0px;
    white-space: nowrap;
    display: block;
    content: $content;
    text-transform: uppercase;
    color: #f9f9fb;
    font-size: 160px;
    position: absolute;
    font-weight: 700;
  }
}
@mixin bgTitleRight($content){
  position: relative;
  &:before{
    top: -60px;
    width: 100%;
    overflow: hidden;
    margin-left: 0px;
    white-space: nowrap;
    display: block;
    content: $content;
    text-transform: uppercase;
    color: #f9f9fb;
    font-size: 160px;
    position: absolute;
    font-weight: 700;
    text-align: right;
  }
}
%last{
  display: flex;
  width: 100%;
  margin: 80px auto 40px;
  @media(max-width: 767px){
    margin: 40px auto 20px;
  }
  justify-content: center;
  position: relative;
  color: #323947;
  span{
    position: relative;
    z-index: 2;
    padding-top: 20px;
  }
  &:before{
    position: absolute;
    content: '';
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &--subtitle{
    @media(max-width: 767px){
      font-size: 16px;
    }
    font-size: 24px;
  }
  &--t-center{
    text-align: center;
  }
  &--title{
    @media(max-width: 767px){
      font-size: 32px;
    }
    font-size: 48px;
  }
  &--ok-bg{
    &:before{
      width: 153px;
      height: 157px;
      background-image: url(../images/icons/ok-bg.png);
    }
  }
  &--medal-bg{
    &:before{
      width: 173px;
      height: 186px;
      background-image: url(../images/icons/medal-icon.png);
    }
  }
}
