.service{
  &:before{
    color: #f3f3f8;
    font-size: 48px;
    font-weight: 600;
    content: "0" counter(my-badass-counter);
    counter-increment: my-badass-counter;
  }
  &__thumb{
    position: relative;
    max-width: 508px;
    @media(max-width: 767px){
      max-width: 100%;
      height: auto;
    }
    width: 100%;
    height: 332px;
    &:before{
      content: '';
      position: absolute;
      left: -31px;
      bottom: -80px;
      width: 444px;
      @media(max-width: 767px){
        width: 260px;
        height: 280px;
        left: 10px;
      }
      height: 393px;
      border: 15px solid #f3f3f8;
    }
    img{
      max-width: 100%;
      position: relative;
      z-index: 2;
      box-shadow: 17px 18px 123px -27px rgba(0,0,0,0.75);
    }
  }
  &__title{
    font-size: 24px;
    margin-top: 12px;
    margin-bottom: 10px;
    padding-bottom: 50px;
    position: relative;
    &:after{
      position: absolute;
      width: 4px;
      height: 41px;
      content: '';
      left: 0;
      bottom: 0;
      margin: auto;
      background-color: #ff7e58;
    }
  }
  &__desc{
    font-size: 18px;
    color: #000000;
    line-height: 30px;
  }
  &__btn{
    @extend %btn;
  }
}
