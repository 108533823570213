.project{
  &__row{
    display: flex;
    flex-wrap: wrap;
    @media(max-width: 767px){
      flex-direction: column;
    }
    width: 100%;
    &--mt-10{
      margin-top: 10px;
    }
    &--wrap{
      flex-wrap: wrap;
    }
  }
  &__content-side{
    padding-top: 35px;
    @extend %content-dark-bg;
  }
  &__col-2{
    width: calc(100% / 2);
    @media(max-width: 767px){
      width: 100%;
    }
    &--to-bottom{
      display: flex;
      align-items: flex-end;
    }
    &--content-side{
      @extend %content-dark-bg;
      @media(max-width: 767px){
        padding-top: 20px;
      }
      @media(min-width: 768px){
        padding-left: 30px;
      }
    }
    img{
      max-width: 100%;
    }
  }
  &__col-3{
    width: calc(100% / 3);
    @media(max-width: 767px){
      width: 100%;
      margin-bottom: 20px;
    }
    &--y-centered{
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    &--pb-50{
      padding-bottom: 50px;
    }
  }
  &__stat{
    min-width: 111px;
    height: 111px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 72px;
    font-weight: 600;
    color: #ffffff;
    &--orange{
      color: #ff7e58;
      &:before{
        opacity: 0.8 !important;
        z-index: -1;
      }
    }
    &:before{
      position: absolute;
      width: 111px;
      height: 111px;
      opacity: 0.05;
      border: 15px solid #f3f3f8;
      left: 0;
      right: 0;
      margin: auto;
      content: '';
    }
    &:after{
      position: absolute;
      width: 27px;
      height: 4px;
      left: 0;
      right: 0;
      bottom: 7px;
      margin: auto;
      background-color: #ff7e58;
      content: '';
    }
  }
  &__desc{
    color: #ffffff;
    &--dark{
      color: #323947;
    }
    font-size: 20px;
    padding: 0 30px;
    text-align: center;
  }
}
