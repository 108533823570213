%bg-cover{
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
%bg-contain{
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
