.review{
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-bottom: 80px;
  $parent: &;
  @media(max-width: 767px){
    flex-direction: column;
  }
  @media(min-width: 768px){
    &:first-of-type{
      margin-top: 80px;
    }
  }

  &:nth-of-type(even){
    #{$parent}__avatar{
      @media(min-width: 768px){
        order: 1;
      }
    }
    #{$parent}__content{
      &:before{

        right: 262px;
        top: 20px;
        width: 95px;
        height: 90px;
      }
    }
  }
  &:nth-of-type(odd){
    #{$parent}__content{
      &:before{
        width: 144px;
        height: 121px;
        right: 0;
        top: 20px;
      }
    }
  }
  &__avatar{
    min-width: 252px;
    height: 252px;
    border: 15px solid #ff7e58;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width: 90%;
      max-height: 90%;
    }
  }
  &__content{
    flex-grow: 1;
    @media(min-width: 768px){
      padding-left: 30px;
    }
    position: relative;
    &:before{
      z-index: -1;
      position: absolute;
      @extend %bg-contain;
      content: '';
      background-image: url(../images/reviews/content-before.png);
    }
    h3{
      font-size: 20px;
      font-weight: 700;
      color: #000000;
      text-transform: uppercase;
    }
    p{
      line-height: 30px;
      font-weight: 400;
      color: #000000;
      font-size: 20px;
      margin: 0;
    }
  }
}
