.auditory{
  width: 100%;
  &--bg-title{
    @include bgTitle('точно для вас');
  }
  &__plate{
    @extend %plate;
  }
  &__title{
    @extend %title;
    @extend %title--underscore;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  &__desc{
    text-align: center;
    line-height: 36px;
    @media(max-width: 767px){
      line-height: 30px;
    }
  }
  &__txt{
    color: #323947;
    font-size: 20px;
    &:not(:last-of-type){
      margin: 0 0 15px;
    }
    &--mw-700{
      margin-left: auto;
      margin-right: auto;
      max-width: 700px;
    }
  }
  &__box{
    list-style: none;
    padding: 0;
    margin: 55px 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    counter-reset: audit-counter;
  }
  &__col-3{
    width: calc(100% / 3);
    min-width: 290px;
    margin-bottom: 60px;
  }
  &__item{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    &:before{
      position: absolute;
      left: 60px;
      color: #ff7e58;
      font-size: 30px;
      text-transform: uppercase;
      font-weight: 700;
      content: "0" counter(audit-counter) ".";
      counter-increment: audit-counter;
    }
  }
  &__text{
    text-align: center;
    font-size: 20px;
    color: #000000;
    line-height: 30px;
  }
  &__icon{
    border: 15px solid #f3f3f8;
    width: 111px;
    height: 111px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
