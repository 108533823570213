.team{
  width: 100%;
  &__box{
    display: flex;
    flex-wrap: wrap;
  }
  &__col-4{
    width: calc(100% / 4);
    min-width: 270px;
    margin-bottom: 40px;
    @media(max-width: 767px){
      width: 100%;

    }
    display: flex;
    align-items: center;
    flex-direction: column;
    &--plr-16{
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  &__pic{
    width: 252px;
    height: 252px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 15px solid #ff7e58;
    img{
      max-width: 95%;
      max-height: 95%
    }
  }
  &__name{
    color: #000000;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    padding: 26px 0;
  }
  &__desc{
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }
  &__btn{
    margin-top: 15px;
    font-size: 18px;
    color: #ff7e58;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;
    &:after{
      content: '';
      background-image: url(../images/icons/arrow-orange.png);
      @extend %bg-contain;
      width: 18px;
      height: 13px;
      margin-left: 16px;
      display: inline-block;
    }
    &:hover{
      color: #323947;
    }
  }
}
