// gallery main layout
.ghost-gal{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0;
	pointer-events: none;
	transition: 0.2s ease-in;
	background-color: rgba(0,0,0,0.6);
	z-index: 10;
	&--enabled{
		opacity: 1;
		pointer-events: auto;
	}
	&__container{
		height: 100%;
	}
	&__close{
		z-index: 4;
		right: 30px;
		top: 30px;
		@extend %close-icon;
		@extend %close-icon--abs;
		@extend %close-icon--white;
	}
	img{
		max-width: 90%;
		max-height: 90%;
	}
	&__slide{
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	&__wrapper{
		align-items: stretch;
		height: 100%;
	}
	&__chev{
		display: block;
		z-index: 4;
		position: absolute;
		width: 32px;
		height: 36px;
		bottom: 0px;
		top: 0;
		margin: auto;
		@extend %bg-contain;
		background-image: url(../images/icons/chev-left-white.svg);
		&--right{
			right: 30px;
			transform: rotate(-180deg);
		}
		&--left{
			left: 30px;
		}
	}
}
