%box{
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  &--center-y-xs{
    @media(max-width: 767px){
      justify-content: center;
    }
  }
}
