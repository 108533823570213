%close-icon{
  width: 30px;
  height: 30px;
  &--rel{
    position: relative;
  }
  &--abs{
    position: absolute;
  }
  &--white{
    &:before, &:after{
      background-color: #fff;
    }
  }
  &:before{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 25px;
    height: 3px;
    content: '';
    margin: auto;
    transform: rotate(45deg);
  }
  &:after{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 25px;
    height: 3px;
    content: '';
    margin: auto;
    transform: rotate(-45deg);
  }
}
